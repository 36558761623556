import "react-responsive-modal/styles.css"
import React from "react"
import { Router } from "@reach/router"
import { useProfile } from "./auth/auth.hooks"
import { Login } from "./auth/Login.screen"
import Layout from "../components/layout"
import { StickyImage } from "../components/stickyImage"
import { ForgotPassword } from "./auth/ForgotPassword.screen"
import { ResetPassword } from "./auth/ResetPassword.screen"
import { Signup } from "./auth/Signup.screen"
import { Profile } from "./profile/Profile"
import { MissionSearch } from "./Missions/MissionSearch"

export const App = () => {
    return (
        <Layout>
            <AppLogic />
        </Layout>
    )
}
export const AppLogic = () => {
    const { loggedIn } = useProfile()
    return (
        <>
            <StickyImage />
            {loggedIn ? <AppRouter /> : <AuthRouter />}
        </>
    )
}

const AppRouter = () => {
    return (
        <Router basepath="/app">
            <Profile path="/" />
            <MissionSearch path="/search" />
            <Profile path="/" default />
        </Router>
    )
}
const AuthRouter = () => {
    return (
        <Router basepath="/app">
            <Login path="/login" />
            <ForgotPassword path="/forgot" />
            <ResetPassword path="/reset" />
            <Signup path="/signup" />
            <MissionSearch path="/search" />
            <Login path="/" />
        </Router>
    )
}
