import React, { useEffect, useState } from "react"
import { firestore } from "../../firebase"
import styled from "@emotion/styled"
import { OrangeButton } from "../../components/buttons"
import { useProfile } from "../auth/auth.hooks"
import swal from "@sweetalert/with-react"
import { LoginRow } from "../auth/components/LoginRow"
import { BaseInput, BaseLabel } from "./BaseFields"
import Modal from "react-responsive-modal"

export const VolunteerProfile = ({ id }) => {
    const [user, setUser] = useState({})
    const [open, setOpen] = useState(false)
    useEffect(() => {
        firestore
            .collection("users")
            .doc(id)
            .get()
            .then((snap) => {
                console.log(snap)
                setUser(snap.data() || {})
            })
    }, [id])

    return (
        <Wrapper>
            <Avatar src={user.photoURL} alt="" />
            <div>
                <h1>{user.name}</h1>
                <h2>
                    {user.schoolLevel} - {user.age}ans
                </h2>
                <p>{user.experience}</p>
                <AddPoints onClick={() => setOpen(true)}>Attribuer des heures</AddPoints>
            </div>
            <Modal open={open} onClose={() => setOpen(false)}>
                <AddPointsScreen applicant={id} onEnded={() => setOpen(false)} />
            </Modal>
        </Wrapper>
    )
}

const AddPointsScreen = ({ applicant, onEnded }) => {
    const { user } = useProfile()
    const submit = async (e) => {
        e.preventDefault()
        const hours = Number(e.target.hours.value) || 0

        if (!hours) {
            await swal({
                icon: "warning",
                content: (
                    <p>
                        <br />
                        <br />
                        Saisie invalide
                    </p>
                )
            })
            return
        }

        firestore.collection("pointsAttributions").add({
            applicant,
            who: user?.uid,
            hours
        })

        await swal({
            icon: "success",
            content: (
                <p>
                    <br />
                    <br />
                    Enregistré
                </p>
            )
        })
        onEnded && onEnded()
    }

    return (
        <form onSubmit={submit}>
            <LoginRow>
                <BaseLabel htmlFor="hours">Nombre d'heures :</BaseLabel>
                <BaseInput id="hours" type="number" name="hours" placeholder="Nombre d'heures" required />
            </LoginRow>
            <OrangeButton type="submit">Enregistrer</OrangeButton>
        </form>
    )
}

const Wrapper = styled.article`
    box-sizing: border-box;
    border: 0.08rem solid black;
    border-radius: 0.25rem;
    margin: 0.5rem 0;
    background-color: white;
    padding: 1rem;
    width: 90vw;
    max-width: 100%;

    display: flex;
    align-items: center;

    & h1 {
        font-size: 1.1rem;
        margin: 0.1rem 0;
    }
    & h2 {
        font-size: 1rem;
        margin: 0.1rem 0;
    }
    & p {
        font-size: 1rem;
        margin: 0.1rem 0;
    }
    & > div {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        padding-left: 1rem;
    }
`

const AddPoints = styled(OrangeButton)`
    align-self: self-end;
`
const Avatar = styled.img`
    flex: 0 0 auto;
    height: 3rem;
    width: 3rem;
    border: 1px solid black;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
    margin: 0.25rem;
`
