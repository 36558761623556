import { useProfile } from "../auth/auth.hooks"
import { ASSOCIATION } from "./profiles.types"
import React from "react"
import { VolunteerProfile } from "../volunteer/VolunteerProfile"
import { AssociationProfile } from "../association/AssociationProfile"

export const Profile = () => {
    const { user, account } = useProfile()

    if (!user || !account) return null

    if (account.type === ASSOCIATION) {
        return <AssociationProfile />
    }

    return <VolunteerProfile />
}
