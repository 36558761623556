import React, { useState } from "react"
import { useProfile } from "./auth.hooks"
import { LoginCard, LoginForm } from "./components/LoginCard"
import { BaseInput, BaseLabel } from "../components/BaseFields"
import { OrangeButton } from "../../components/buttons"
import { SectionTitle } from "../../components/SectionTitle"
import { LoginLink } from "./components/LoginLink"
import { LoginRow } from "./components/LoginRow"
import styled from "@emotion/styled"
import { LoginError } from "./components/LoginError"
import { useNavigate } from "@reach/router"

export const Login = () => {
    const navigate = useNavigate()
    const { login } = useProfile()
    const [errors, setErrors] = useState([])

    return (
        <>
            <SectionTitle>Connexion</SectionTitle>
            <LoginCard>
                <LoginForm onSubmit={onSubmit}>
                    {errors.map((error) => (
                        <LoginError>{error}</LoginError>
                    ))}
                    <LoginRow>
                        <BaseLabel htmlFor="email">Email</BaseLabel>
                        <BaseInput type="email" name="email" id="email" placeholder="Email" />
                    </LoginRow>
                    <LoginRow>
                        <BaseLabel htmlFor="password">Mot de passe</BaseLabel>
                        <BaseInput type="password" name="password" id="password" placeholder="Mot de passe" />
                        <RightTextRow>
                            <LoginLink to="/app/forgot">Mot de passe oublié ?</LoginLink>
                        </RightTextRow>
                    </LoginRow>
                    <LoginRow>
                        <OrangeButton>Me connecter</OrangeButton>
                    </LoginRow>

                    <CenteredTextRow>
                        <LoginLink to="/app/signup">Pas encore de compte ? Inscrivez vous maintenant !</LoginLink>
                    </CenteredTextRow>
                </LoginForm>
            </LoginCard>
        </>
    )

    async function onSubmit(event) {
        event.preventDefault()

        try {
            await login(event.target.email.value, event.target.password.value)
            setErrors([])
            await navigate("/app/")
        } catch (e) {
            console.error(e)
            setErrors(["Mauvaise combinaison email/mot de passe"])
        }
    }
}

const CenteredTextRow = styled(LoginRow)`
    text-align: center;
`
const RightTextRow = styled.div`
    text-align: right;
`
