import React, { useEffect, useMemo, useState } from "react"
import { Table } from "./Table"
import { Pagination } from "./Pagination"
import { firestore, queryResultToArray } from "../../firebase"
import { MissionsStates } from "../enums/MissionsStates"
import { format } from "date-fns"
import { Thead, Th, Td, Tbody, Tr } from "react-super-responsive-table"
import styled from "@emotion/styled"
import { MissionStatus } from "../../enums/MissionStatus"
import { OrangeButton } from "../../components/buttons"
import Modal from "react-responsive-modal"
import { VolunteerProfile } from "./VolunteerProfile"

const ITEM_BY_PAGES = 30
export const MissionList = ({ filter = () => true, owner, applicant }) => {
    const [page, setPage] = useState(1)
    const [selectedMission, setSelectedMission] = useState(null)
    const [missions, setMissions] = useState([])
    useEffect(() => {
        let query = firestore.collection("missions")

        if (owner) {
            query = query.where("owner", "==", owner)
        }
        if (applicant) {
            query = query.where("applicants", "array-contains", applicant)
        }
        const unsub = query.onSnapshot({
            next(missions) {
                setMissions(queryResultToArray(missions))
            }
        })
        return () => {
            unsub()
        }
    }, [owner, applicant])

    const filteredMissions = useMemo(() => {
        const unsortedMissions = filter ? missions.filter(filter) : missions
        return unsortedMissions.sort((a, b) => -a.startAt.localeCompare(b.startAt))
    }, [filter, missions])

    const paginated = useMemo(() => {
        return filteredMissions.slice((page - 1) * ITEM_BY_PAGES, page * ITEM_BY_PAGES)
    }, [page, filteredMissions])

    return (
        <MissionListContainer>
            <Table>
                <Thead>
                    <Tr>
                        <Th>Niveau</Th>
                        <Th>Sujet</Th>
                        <Th>Lieu</Th>
                        <Th>Départ - Fin</Th>
                        <Th>État</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {paginated.map((mission) => {
                        const startAt = new Date(mission.startAt)
                        const endAt = new Date(mission.endAt)
                        return (
                            <Tr>
                                <Td>{mission.studentLevel}</Td>
                                <Td>{mission.subject}</Td>
                                <Td>{mission.location}</Td>
                                <Td>
                                    {format(startAt, "dd/MM/yyyy")} - <br />
                                    {format(endAt, "dd/MM/yyyy")}
                                </Td>
                                <Td>
                                    {!applicant && mission.status === MissionStatus.validated ? (
                                        <OrangeButton style={{ width: "100%" }} onClick={() => setSelectedMission(mission)}>
                                            {(mission.applicants || []).length} profile(s)
                                        </OrangeButton>
                                    ) : (
                                        MissionsStates[mission.status]
                                    )}
                                </Td>
                            </Tr>
                        )
                    })}
                </Tbody>
            </Table>

            <Pagination count={Math.ceil(filteredMissions.length / ITEM_BY_PAGES)} current={page} onSelect={setPage} />

            <Modal open={selectedMission} onClose={() => setSelectedMission(null)}>
                {selectedMission != null && (
                    <div>
                        {(selectedMission.applicants || []).map((applicant) => {
                            return <VolunteerProfile key={applicant} id={applicant} />
                        })}
                    </div>
                )}
            </Modal>
        </MissionListContainer>
    )
}

const MissionListContainer = styled.div`
    display: flex;
    flex-direction: column;
`
