import React from "react"
import { ProfileBoard, ProfileContainer, ProfileDetails } from "../profile/components/ProfilteContainer"
import { AssociationProfileEdit } from "./AssociationProfileEdit"
import { AssociationDashboard } from "./AssociationDashboard"

export const AssociationProfile = () => (
    <ProfileContainer>
        <ProfileBoard>
            <AssociationDashboard />
        </ProfileBoard>
        <ProfileDetails>
            <AssociationProfileEdit />
        </ProfileDetails>
    </ProfileContainer>
)
