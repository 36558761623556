import React, { memo, useCallback, useState } from "react"
import styled from "@emotion/styled"
import { Save, X } from "react-feather"
import { InvertedOrangeButton } from "../../components/buttons"

export const Editable = ({ as = "div", id, value, onChange, onSave, multiline = false }) => {
    const [innerValue, setInnerValue] = useState(value)
    const [resync, setResync] = useState(true)
    const toggleSync = () => setResync((v) => !v)
    const update = useCallback(
        (value) => {
            console.log(resync)
            setInnerValue(value)
            onChange && onChange(value)
        },
        [onChange, resync]
    )
    const cancel = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setInnerValue(value)
        toggleSync()
    }
    const save = (e) => {
        e.preventDefault()
        e.stopPropagation()
        console.log(innerValue, onSave)
        onSave && onSave(innerValue)
        toggleSync()
    }
    const input = useCallback(
        (e) => {
            update(e.target.innerText)
        },
        [update]
    )

    console.log("re-render P", innerValue)
    return (
        <form onSubmit={save}>
            <EditableRender key={"field"} id={id} as={as} onInput={input} multiline={multiline} value={innerValue} />
            <ActionBar display={innerValue !== value}>
                <ActionButton type="button" onClick={cancel}>
                    <X size="1.2em" />
                </ActionButton>
                <ActionButton type="submit">
                    <Save size="1.2em" />
                </ActionButton>
            </ActionBar>
        </form>
    )
}
const ActionButton = styled(InvertedOrangeButton)`
    padding: 0.3rem;
`

const EditableRender = memo(
    ({ id, as, onInput, value, multiline }) => {
        return (
            <DefaultEditableStyle
                id={id}
                as={as}
                key={"field" + (id || "")}
                multiline={multiline}
                contentEditable
                onInput={onInput}
                onKeyPress={(e) => {
                    if (e.key.toLowerCase() === "enter") {
                        if (multiline && !e.ctrlKey) return
                        const submitEvent = new Event("submit", {
                            bubbles: true
                        })
                        e.target.parentElement.dispatchEvent(submitEvent)
                        e.target.blur()
                    }
                }}
                dangerouslySetInnerHTML={{
                    __html: value
                }}
            />
        )
    },
    (prev, next) => {
        return prev.as === next.as && prev.onInput === next.onInput && prev.id === next.id
    }
)
const DefaultEditableStyle = styled.div`
    word-break: break-word;
    white-space: ${(props) => (props.multiline ? "pre-line" : "normal")};
`
const ActionBar = styled.div`
    opacity: ${(props) => (props.display ? "1" : "0")};
    display: flex;
    justify-content: flex-end;
    font-size: 0.8rem;
`
