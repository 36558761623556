import React, { useState } from "react"
import { useProfile } from "./auth.hooks"
import { LoginCard, LoginForm } from "./components/LoginCard"
import { BaseInput, BaseLabel } from "../components/BaseFields"
import { OrangeButton } from "../../components/buttons"
import { SectionTitle } from "../../components/SectionTitle"
import { LoginRow } from "./components/LoginRow"
import { LoginError } from "./components/LoginError"

export const ResetPassword = () => {
    const { resetPassword } = useProfile()
    const [errors, setErrors] = useState([])

    return (
        <>
            <SectionTitle>Nouveau mot de passe</SectionTitle>
            <LoginCard>
                <LoginForm onSubmit={onSubmit}>
                    {errors.map((error) => (
                        <LoginError>{error}</LoginError>
                    ))}
                    <LoginRow>
                        <BaseLabel htmlFor="password">Nouveau mot de passe</BaseLabel>
                        <BaseInput type="password" name="password" id="password" placeholder="Mot de passe" />
                    </LoginRow>
                    <LoginRow>
                        <OrangeButton>Enregistrer</OrangeButton>
                    </LoginRow>
                </LoginForm>
            </LoginCard>
        </>
    )

    async function onSubmit(event) {
        event.preventDefault()

        try {
            await resetPassword(event.target.password.value)
            setErrors([])
        } catch (e) {
            console.error(e)
            setErrors(["Mot de passe trop simple"])
        }
    }
}
