import styled from "@emotion/styled"
import { Table as T } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"

export const Table = styled(T)`
    background-color: white;
    font-size: 0.7rem;
    width: 100%;
    border-collapse: collapse;

    & tbody > tr:nth-of-type(2n + 1) {
        background-color: #f9d7cd;
    }
    & td,
    & th {
        border-right: 2px solid black;
        padding: 0.6rem;
        &:last-of-type {
            border-right: none;
        }
    }
    & thead {
        border-bottom: 2px solid black;
    }
`
