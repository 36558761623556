import React, { useState } from "react"
import { ProfileAvatar, ProfileBoard, ProfileContainer, ProfileDetails } from "../profile/components/ProfilteContainer"
import { InvertedOrangeButton, OrangeButton } from "../../components/buttons"
import { Editable } from "../components/Editable"
import { FieldLabel } from "../components/FieldLabel"
import { useProfile } from "../auth/auth.hooks"
import { MissionList } from "../components/MissionList"
import { useNavigate } from "@reach/router"
import { SectionTitle } from "../../components/SectionTitle"
import sweetAlert from "@sweetalert/with-react"

export const VolunteerProfile = () => {
    const navigate = useNavigate()
    const { account, user, updateUserField, logout } = useProfile()

    const [experience, setExperience] = useState(account.experience || "")
    const [schoolLevel, setSchoolLevel] = useState(account.schoolLevel || "")
    const [age, setAge] = useState(account.age || 0)
    const [name, setName] = useState(account.name || "")
    const [points, setPoints] = useState(account.points || "")
    const [diplomas, setDiplomas] = useState(account.diplomas || "")
    const [photoURL, setPhotoURL] = useState(user.photoURL || "")

    return (
        <ProfileContainer>
            <ProfileBoard>
                <SectionTitle>Mon Profil</SectionTitle>
                <OrangeButton onClick={() => navigate("/app/search")}>Rechercher une mission</OrangeButton>
                <aside>
                    <InvertedOrangeButton
                        onClick={() =>
                            sweetAlert({
                                content: <p>Bientôt ;)</p>,
                                buttons: false
                            })
                        }
                    >
                        Accéder à mes avantages
                    </InvertedOrangeButton>
                </aside>
                <h1>Historique de missions</h1>
                <MissionList applicant={user.uid} />
            </ProfileBoard>
            <ProfileDetails>
                <header>
                    <h1>Profil bénévole</h1>
                </header>
                <ProfileAvatar
                    src={photoURL}
                    alt="Photo de profil"
                    onSave={async (file) => {
                        const url = await updateUserField("photoURL", file)
                        setPhotoURL(url)
                    }}
                />
                <dl>
                    <FieldLabel as={"dt"} hidden={true} htmlFor="name">
                        Nom
                    </FieldLabel>
                    <Editable id="name" as={"dd"} value={name} onSave={async (v) => setName(await updateUserField("name", v))} />
                    <FieldLabel as={"dt"} hidden={true} htmlFor="age">
                        Age
                    </FieldLabel>
                    <p>
                        <span className="hours">{account.hoursSpent || 0}h</span> de cours donné
                    </p>
                    <Editable id="age" as={"dd"} value={age + " ans"} onSave={async (v) => setAge(await updateUserField("age", parseInt(v)))} />
                    <FieldLabel as={"dt"} htmlFor="schoolLevel">
                        Niveau Scolaire
                    </FieldLabel>
                    <Editable
                        id="schoolLevel"
                        as={"dd"}
                        value={schoolLevel}
                        onSave={async (v) => {
                            console.log(v)
                            setSchoolLevel(await updateUserField("schoolLevel", v))
                        }}
                    />
                    <FieldLabel as={"dt"} htmlFor="diplomas">
                        Diplôme(s) :
                    </FieldLabel>
                    <Editable id="diplomas" as={"dd"} multiline={true} value={diplomas} onSave={async (v) => setDiplomas(await updateUserField("diplomas", v))} />
                    <FieldLabel as={"dt"} htmlFor="experience">
                        Expérience associative
                    </FieldLabel>
                    <Editable id="experience" as={"dd"} multiline={true} value={experience} onSave={async (v) => setExperience(await updateUserField("experience", v))} />
                </dl>

                <InvertedOrangeButton onClick={logout}>Me déconnecter</InvertedOrangeButton>
            </ProfileDetails>
        </ProfileContainer>
    )
}
