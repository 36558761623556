import React, { useState } from "react"
import styled from "@emotion/styled"
import { LocationAutoComplete } from "../../../components/LocationAutoComplete"
import { BaseInput, BaseLabel, BaseTextArea } from "../../components/BaseFields"
import { FormRow } from "../../components/FormRow"
import { rates } from "../../enums/Rates"
import { OrangeButton } from "../../../components/buttons"

export const CreateMission = ({ onSave }) => {
    const [location, setLocation] = useState(null)
    return (
        <Form
            onSubmit={(e) => {
                e.preventDefault()

                const result = {
                    studentLevel: e.target.studentLevel.value,
                    subject: e.target.subject.value,
                    rates: e.target.rates.value,
                    seance: e.target.seance.value,
                    startAt: new Date(e.target.startAt.value).toISOString(),
                    endAt: new Date(e.target.endAt.value).toISOString(),
                    description: e.target.description.value,
                    location: location?.value?.structured_formatting?.main_text || location.label
                }
                onSave && onSave(result)
            }}
        >
            <FormRow>
                <BaseLabel htmlFor="studentLevel">Niveau scolaire</BaseLabel>
                <BaseInput type="text" name="studentLevel" id="studentLevel" placeholder="Niveau scolaire" required={true} />
            </FormRow>
            <FormRow>
                <BaseLabel htmlFor="subject">Sujet</BaseLabel>
                <BaseInput type="text" name="subject" id="subject" placeholder="Sujet" required={true} />
                <Tips>Exemple: Français / Histoire / etc.</Tips>
            </FormRow>
            <FormRow>
                <BaseLabel htmlFor="seance">Durée d'une scéance</BaseLabel>
                <BaseInput type="text" name="seance" id="seance" placeholder="Durée d'une scéance" required={true} />
                <Tips>Exemple : 1h30</Tips>
            </FormRow>
            <FormRow>
                <BaseLabel htmlFor="rates">Fréquence</BaseLabel>
                <BaseInput as="select" name="rates" id="rates" placeholder="Fréquence" required={true}>
                    {rates.map((rate, i) => (
                        <option key={rate} value={rate}>
                            {rate}
                        </option>
                    ))}
                </BaseInput>
            </FormRow>
            <FormRow>
                <BaseLabel htmlFor="startAt">Démarre le</BaseLabel>
                <BaseInput type="date" name="startAt" id="startAt" placeholder="Démarre le" required={true} />
            </FormRow>
            <FormRow>
                <BaseLabel htmlFor="endAt">Fini le</BaseLabel>
                <BaseInput type="date" name="endAt" id="endAt" placeholder="Fini le" required={true} />
            </FormRow>
            <FormRow>
                <BaseLabel htmlFor="location">Localisation</BaseLabel>
                <LocationAutoComplete defaultValue={location} onChange={setLocation} />
            </FormRow>
            <FormRow>
                <BaseLabel htmlFor="description">Description</BaseLabel>
                <BaseTextArea type="textarea" name="description" id="description" placeholder="Description de la mission" required={true} />
            </FormRow>

            <OrangeButton>Soumettre</OrangeButton>
        </Form>
    )
}

const Form = styled.form`
    width: 50vw;
    max-width: 100%;
`

const Tips = styled.span`
    color: #4b4b4b;
    font-size: 1rem;
`
