import React, { useCallback, useState } from "react"
import { useProfile } from "./auth.hooks"
import { LoginCard, LoginForm } from "./components/LoginCard"
import { BaseInput, BaseLabel, BaseTextArea } from "../components/BaseFields"
import { OrangeButton } from "../../components/buttons"
import { SectionTitle } from "../../components/SectionTitle"
import { LoginRow } from "./components/LoginRow"
import styled from "@emotion/styled"
import { SignupChooseButton } from "./components/SignupChooseButton"
import { colors } from "../../css/colors"
import { IoIosAdd, IoIosRemove } from "react-icons/io"
import { ASSOCIATION, VOLUNTEER } from "../profile/profiles.types"
import { useNavigate } from "@reach/router"

export const Signup = () => {
    return (
        <>
            <SectionTitle>Inscription</SectionTitle>
            <LoginCard>
                <StateRenderer />
            </LoginCard>
        </>
    )
}

const StateRenderer = () => {
    const [typeSelection, setTypeSelection] = useState(null)

    switch (typeSelection) {
        case VOLUNTEER:
            return <VolunteerForm />
        case ASSOCIATION:
            return <AssociationForm />
        default:
            return <ChooseScreen onSelect={(selection) => setTypeSelection(selection)} />
    }
}

const AssociationForm = () => {
    const navigate = useNavigate()
    const [membersCount, setMembersCount] = useState(1)

    const { signup } = useProfile()

    const addMember = () => {
        setMembersCount((prevState) => {
            return prevState + 1
        })
    }

    const removeMember = () => {
        setMembersCount((prevState) => {
            return prevState - 1
        })
    }

    return (
        <SignUpForm onSubmit={onSubmit}>
            <LoginRow>
                <BaseLabel htmlFor="name">Nom de l'association</BaseLabel>
                <BaseInput type="text" name="name" id="name" placeholder="Nom de l'association" required />
            </LoginRow>
            <LoginRow>
                <BaseLabel htmlFor="specif">Spécificité de l’association</BaseLabel>
                <BaseInput type="text" name="specif" id="specif" placeholder="Spécificité de l’association" required />
            </LoginRow>
            <LoginRow>
                <BaseLabel htmlFor="description">Description</BaseLabel>
                <BaseTextArea id="description" rows="4" cols="50" name="description" placeholder="Tapez votre description..." required />
            </LoginRow>
            <BaseLabel>Constitution du bureau</BaseLabel>
            {Array(membersCount)
                .fill(0)
                .map((_, index) => {
                    return (
                        <CustomRow className="memberRow" key={index}>
                            <BaseInput type="text" name={"memberName" + index} id="memberName" placeholder="Nom du membre" required />
                            <BaseInput type="text" name={"role" + index} id="role" placeholder="Rôle" required />
                        </CustomRow>
                    )
                })}
            <Buttons>
                <AddRowButton onClick={addMember}>
                    <IoIosAdd size="1.3rem" />
                    <p>Ajouter un membre</p>
                </AddRowButton>
                <AddRowButton onClick={removeMember}>
                    <IoIosRemove size="1.3rem" />
                    <p>Supprimer un membre</p>
                </AddRowButton>
            </Buttons>

            <LoginRow>
                <flexDiv>
                    <BaseLabel htmlFor="website">Site Web de l'association (Optionnel)</BaseLabel>
                </flexDiv>
                <BaseInput type="text" name="website" id="website" placeholder="Site Web de l'association" />
            </LoginRow>
            <LoginRow>
                <BaseLabel htmlFor="email">Email</BaseLabel>
                <BaseInput type="email" name="email" id="email" placeholder="Email" required />
            </LoginRow>
            <PasswordField />
            <OrangeButton>S'inscrire</OrangeButton>
        </SignUpForm>
    )

    async function onSubmit(event) {
        event.preventDefault()

        const form$ = event.target
        const password = form$.password.value
        const email = form$.email.value
        const name = form$.name.value
        const description = form$.description.value
        const specif = form$.specif.value
        const website = form$.website.value
        const members = [...form$.querySelectorAll(".memberRow")].map((member$, index) => {
            return {
                memberName: form$[`memberName${index}`].value,
                role: form$[`role${index}`].value
            }
        })

        const userInfo = {
            name,
            description,
            specif,
            website,
            members,
            type: ASSOCIATION
        }

        await signup(email, password, userInfo)
        await navigate("/app/")
    }
}

const CustomRow = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 1rem;
`

const Buttons = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const AddRowButton = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    width: fit-content;
    margin-bottom: 1rem;
    font-size: 80%;
`

const SignUpForm = styled(LoginForm)`
    width: 100%;
`

const VolunteerForm = () => {
    const navigate = useNavigate()
    const { signup } = useProfile()

    return (
        <LoginForm onSubmit={onSubmit}>
            <LoginRow>
                <BaseLabel htmlFor="name">Nom & Prénom</BaseLabel>
                <BaseInput type="text" name="name" id="name" placeholder="Nom & Prénom" required />
            </LoginRow>
            <LoginRow>
                <BaseLabel htmlFor="name">Âge</BaseLabel>
                <BaseInput type="number" name="age" id="age" placeholder="Âge" required />
            </LoginRow>
            <LoginRow>
                <BaseLabel htmlFor="schoolLevel">Niveau Scolaire</BaseLabel>
                <BaseInput type="text" name="schoolLevel" id="schoolLevel" placeholder="Niveau Scolaire" required />
            </LoginRow>
            <LoginRow>
                <BaseLabel htmlFor="experience">Expérience associative</BaseLabel>
                <BaseTextArea type="text" name="experience" id="experience" placeholder="Vos expériences en quelques mots..." required />
            </LoginRow>
            <LoginRow>
                <BaseLabel htmlFor="email">Email</BaseLabel>
                <BaseInput type="email" name="email" id="email" placeholder="Email" required />
            </LoginRow>
            <PasswordField />

            <OrangeButton>M'inscrire</OrangeButton>
        </LoginForm>
    )

    async function onSubmit(event) {
        event.preventDefault()

        const password = event.target.password.value
        const email = event.target.email.value
        const name = event.target.name.value
        const experience = event.target.experience.value
        const age = event.target.age.value
        const schoolLevel = event.target.schoolLevel.value

        await signup(email, password, {
            name,
            experience,
            age,
            schoolLevel,
            type: VOLUNTEER
        })
        await navigate("/app/")
    }
}
const PasswordField = () => {
    const [tooShort, setTooShort] = useState(false)

    const updateTooShort = useCallback(() => {
        let timeout = null
        return (event) => {
            clearTimeout(timeout)
            const value = event.target.value
            timeout = setTimeout(() => {
                setTooShort(value.length < 6)
            })
        }
    }, [])

    return (
        <LoginRow>
            <BaseLabel htmlFor="password">Mot de passe</BaseLabel>
            <BaseInput type="password" name="password" id="password" placeholder="Mot de passe" onChange={updateTooShort} required={true} />
            <div>{tooShort && <span style={{ color: colors.alert }}>Mot de passe trop court.</span>}</div>
        </LoginRow>
    )
}

const Message = BaseLabel.withComponent("p")

const SelectionZone = styled.div`
    padding: 1rem;
    & > * {
        margin: 0.5rem 0;
    }
`

const ChooseScreen = ({ onSelect }) => {
    return (
        <>
            <Message>Je suis un :</Message>
            <SelectionZone>
                <SignupChooseButton onClick={() => onSelect(VOLUNTEER)} barColor={colors.volunteer}>
                    Un bénévole
                </SignupChooseButton>
                <SignupChooseButton onClick={() => onSelect(ASSOCIATION)} barColor={colors.association}>
                    Une association
                </SignupChooseButton>
            </SelectionZone>
        </>
    )
}
