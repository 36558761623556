import React, { useState } from "react"
import { ProfileAvatar } from "../profile/components/ProfilteContainer"
import { FieldLabel } from "../components/FieldLabel"
import { Editable } from "../components/Editable"
import { InvertedOrangeButton } from "../../components/buttons"
import { useProfile } from "../auth/auth.hooks"
import styled from "@emotion/styled"

export const AssociationProfileEdit = () => {
    const { account, user, updateUserField, logout } = useProfile()

    const [description, setDescription] = useState(account.description || "")
    const [website, setWebsite] = useState(account.website || "")
    const [specif, setSpecif] = useState(account.specif || "")
    //const [members, setMembers] = useState(account.members || [])

    const [name, setName] = useState(account.name || "")
    const [photoURL, setPhotoURL] = useState(user.photoURL || "")

    return (
        <>
            <header>
                <h1>Profil Association</h1>
            </header>
            <ProfileAvatar
                src={photoURL}
                alt="Photo de profil"
                onSave={async (file) => {
                    const url = await updateUserField("photoURL", file)
                    setPhotoURL(url)
                }}
            />
            <SpecList>
                <FieldLabel as={"dt"} hidden={true} htmlFor="name">
                    Nom
                </FieldLabel>
                <Editable id="name" as={"dd"} value={name} onSave={async (v) => setName(await updateUserField("name", v))} />
                <FieldLabel as={"dt"} htmlFor="specif">
                    Spécificité
                </FieldLabel>
                <Editable
                    id="specif"
                    as={"dd"}
                    value={specif}
                    onSave={async (v) => {
                        console.log(v)
                        setSpecif(await updateUserField("specif", v))
                    }}
                />
                <FieldLabel as={"dt"} htmlFor="description">
                    Description
                </FieldLabel>
                <Editable id="description" as={"dd"} multiline={true} value={description} onSave={async (v) => setDescription(await updateUserField("description", v))} />
                <FieldLabel as={"dt"} htmlFor="website">
                    Site web
                </FieldLabel>
                <Editable
                    id="website"
                    as={"dd"}
                    value={website}
                    onSave={async (v) => {
                        setWebsite(await updateUserField("website", v))
                    }}
                />
            </SpecList>

            <InvertedOrangeButton onClick={logout}>Me déconnecter</InvertedOrangeButton>
        </>
    )
}

const SpecList = styled.dl`
    width: 100%;
`
