import { MissionList } from "../components/MissionList"
import React, { useState } from "react"
import { useProfile } from "../auth/auth.hooks"
import Modal from "react-responsive-modal"
import { CreateMission } from "./components/CreateMission"
import { firestore } from "../../firebase"
import styled from "@emotion/styled"
import { OrangeButton } from "../../components/buttons"
import { MissionStatus } from "../../enums/MissionStatus"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "../../css/react-tabs.css"

export const AssociationDashboard = () => {
    const { user } = useProfile()
    const [openCreateMission, setCreateMission] = useState(false)

    return (
        <>
            <Enroll>
                <EnrollTitle>Vous voulez proposer une mission de bénévolat ?</EnrollTitle>
                <EnrollButton onClick={() => setCreateMission(true)}>Soumettre une mission</EnrollButton>
            </Enroll>
            <Tabs>
                <TabList>
                    <Tab>Missions</Tab>
                    <Tab>Bénévoles</Tab>
                </TabList>
                <TabPanel>
                    <MissionList owner={user.uid} />

                    <Modal open={openCreateMission} onClose={() => setCreateMission(false)}>
                        <CreateMission
                            onSave={(mission) => {
                                mission.status = MissionStatus.submitted
                                mission.owner = user.uid
                                mission.applicants = []

                                firestore.collection("missions").add(mission)
                                setCreateMission(false)
                            }}
                        />
                    </Modal>
                </TabPanel>
                <TabPanel>Liste des bénévoles</TabPanel>
            </Tabs>
        </>
    )
}

const Enroll = styled.aside`
    text-align: center;
`
const EnrollTitle = styled.h1`
    font-size: 1.5rem;
`
const EnrollButton = styled(OrangeButton)`
    font-size: 1.5rem;
`
