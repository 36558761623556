import React, { useState } from "react"
import { useProfile } from "./auth.hooks"
import { LoginCard, LoginForm } from "./components/LoginCard"
import { BaseInput, BaseLabel } from "../components/BaseFields"
import { OrangeButton } from "../../components/buttons"
import { SectionTitle } from "../../components/SectionTitle"
import { LoginLink } from "./components/LoginLink"
import { LoginRow } from "./components/LoginRow"
import styled from "@emotion/styled"
import { LoginError } from "./components/LoginError"

export const ForgotPassword = () => {
    const { forgotPassword } = useProfile()
    const [errors, setErrors] = useState([])
    const [sent, setSent] = useState(false)

    return (
        <>
            <SectionTitle>Mot de passe oublié</SectionTitle>
            <LoginCard>
                {sent ? (
                    <BaseLabel>Email envoyé.</BaseLabel>
                ) : (
                    <LoginForm onSubmit={onSubmit}>
                        {errors.map((error) => (
                            <LoginError>{error}</LoginError>
                        ))}
                        <LoginRow>
                            <BaseLabel htmlFor="email">Email</BaseLabel>
                            <BaseInput type="email" name="email" id="email" placeholder="Email" />
                        </LoginRow>
                        <LoginRow>
                            <OrangeButton>Recevoir l'email</OrangeButton>
                        </LoginRow>

                        <CenteredTextRow>
                            <LoginLink to="/app/signup">J'ai retrouvé mon mot de passe</LoginLink>
                            <LoginLink to="/app/signup">Pas encore de compte ? Inscrivez vous maintenant !</LoginLink>
                        </CenteredTextRow>
                    </LoginForm>
                )}
            </LoginCard>
        </>
    )

    async function onSubmit(event) {
        event.preventDefault()

        try {
            await forgotPassword(event.target.email.value)
            setErrors([])
            setSent(true)
        } catch (e) {
            console.error(e)
            setErrors(["Email invalide"])
        }
    }
}

const CenteredTextRow = styled(LoginRow)`
    text-align: center;
`
