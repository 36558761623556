import styled from "@emotion/styled"
import { IoMdArrowRoundForward } from "react-icons/io"
import React from "react"
import { colors } from "../../../css/colors"

export const SignupChooseButton = ({ children, barColor, ...props }) => {
    return (
        <Wrapper {...props}>
            <IconContainer barColor={barColor}>
                <Icon size="1rem" />
            </IconContainer>
            {children}
        </Wrapper>
    )
}

const Wrapper = styled.button`
    display: block;
    border: none;
    background: none;
    text-decoration: none;
    color: ${colors.darkText};
    cursor: pointer;
    text-align: left;
    font-size: 1rem;
`

const IconContainer = styled.span`
    display: inline-block;
    border: 2px solid ${colors.darkText};
    box-sizing: content-box;
    height: 1rem;
    width: 1rem;
    position: relative;
    margin-right: 0.25rem;
    &:after {
        display: block;
        content: "";

        position: absolute;
        top: -2px;
        left: -4px;
        bottom: -2px;
        right: -2.5px;
        background-color: ${(props) => props.barColor};
        transform-origin: left center;
        transform: scaleX(0.15);
        transition: transform ease-in-out 150ms;
    }
    ${Wrapper}:hover &:after {
        transform: scaleX(0.99);
    }
`

const Icon = styled(IoMdArrowRoundForward)`
    color: ${colors.lightText};
    vertical-align: top;
    position: relative;
    width: 1rem;
    height: 1rem;
    z-index: 1;
`
