import React from "react"
import GooglePlacesAutocomplete from "react-google-places-autocomplete"

export const LocationAutoComplete = ({ defaultValue, onChange, placeholder = "Sélectionnez un lieu", noOptionsMessage = "Pas de lieu trouvé" }) => {
    return (
        <GooglePlacesAutocomplete
            apiKey="AIzaSyC6fX-3RDe-2GdZDwcqDgG1EoIVqZLo4X0"
            autocompletionRequest={{
                componentRestrictions: {
                    country: ["fr"]
                }
            }}
            selectProps={{
                defaultValue,
                onChange,
                placeholder,
                noOptionsMessage: () => noOptionsMessage
            }}
        />
    )
}
